import React from "react";
import Link from "./link";

const Nav = () => (
    <nav className="md:text-right">
      <Link to="/" activeClassName="underline">
        Home
      </Link>
      
      <Link to="/archive/" activeClassName="underline">
        Archive
      </Link>

      <Link to="/sponsorship" activeClassName="underline">
        Sponsorship options
      </Link>
    </nav>
);

export default Nav;
