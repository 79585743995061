import React from "react";
import Link from "../components/link";

const Footer = () => (
  <footer>
    <p className="text-grey text-sm">
      © {new Date().getFullYear()}{" "}
      <Link to="https://kilianvalkhof.com" className="text-white">
        Kilian Valkhof
      </Link>
      . Suggestions encouraged{" "}
      <Link to="https://twitter.com/kilianvalkhof" className="text-white">
        via Twitter
      </Link>
      !
    </p>
  </footer>
);

export default Footer;
