import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const SponsorPage = () => (
  <Layout>
    <SEO title="Unsubscribed" keywords={[]} />

    <div className="p-4 pt-0 max-w-sm m-auto">
      <h2>You were unsubscribed</h2>
      <p>
        We're sad to see you go! If there is a specific reason why you
        unsubscribed then please reach out and we'll see if we can make it
        right.
      </p>
    </div>
  </Layout>
);

export default SponsorPage;
