import React from "react";

import { Link as GatsbyLink } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const Link = ({ children, to, activeClassName, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);

  if (internal) {
    return (
      <GatsbyLink to={to} activeClassName={activeClassName} {...other}>
        {children}
      </GatsbyLink>
    );
  }
  return (
    <OutboundLink href={to} {...other}>
      {children}
    </OutboundLink>
  );
};

export default Link;
