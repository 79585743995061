import PropTypes from "prop-types";
import React from "react";

import Nav from "./nav";
import Electron from "../images/Electron.svg";

const Header = ({ siteTitle }) => (
  <header className="md:pt-32">
    <Nav />

    <h1 className="items-center text-electron">
      <img src={Electron} className="block mx-auto w-1/8" alt="" />
      <span className="font-bold text-4xl">Electron newsletter</span>
    </h1>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;
